.App {
  text-align: center;
  font-size: calc(10px + 2vmin);
  color: #AA336A;
  background-color: #E6E6FA;
}

#button{
  display: block;
  justify-content: flex-end;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
  }